<template>
  <div class="dataProcessing">
    <div class="card_title">数据处理</div>
    <a-input-search
      placeholder="搜索名称"
      v-model="searchValue"
      @search="onSearch"
      :style="{ width: '300px', marginBottom: '20px' }"
    />

    <a-table
      :columns="columns"
      :data-source="processingList"
      :rowKey="(record) => record.id"
      :pagination="pagination"
      @change="tableChange"
    >
      <template slot="status" slot-scope="text, record">
        <span :class="`status-${text}`">{{ statusText(text) }}</span>
        <span v-if="text == '2' && record.errorMessage"
          >({{ record.errorMessage }})</span
        >
      </template>

      <template slot="LastEditors" slot-scope="text, record">
        <div class="creator-container">
          <u-avatar
            class="creator-img"
            :picUrl="record.avatar"
            :name="record.lastModifyUser"
            width="30px"
            height="30px"
            style="margin-right: 10px"
          ></u-avatar>
          <span class="creator-name">{{ record.lastModifyUser }}</span>
        </div>
      </template>
      <div slot="operation" slot-scope="text, record">
        <a @click="log(record)">查看运行日志</a>
      </div>
    </a-table>
  </div>
</template>

<script>
const columns = [
  {
    title: '租户id',
    dataIndex: 'tenantId',
    key: 'tenantId',
    scopedSlots: { customRender: 'tenantId' },
    align: 'left',
  },
  {
    title: '租户名',
    dataIndex: 'tenant',
    key: 'tenant',
    scopedSlots: { customRender: 'tenant' },
    align: 'left',
  },
  {
    title: '编号',
    dataIndex: 'id',
    key: 'id',
    scopedSlots: { customRender: 'id' },
    align: 'left',
  },
  {
    title: '名称',
    dataIndex: 'name',
    key: 'name',
    scopedSlots: { customRender: 'name' },
    align: 'left',
  },

  {
    title: '运行次数',
    dataIndex: 'runTime',
    key: 'runTime',
    scopedSlots: { customRender: 'runTime' },
    align: 'left',
  },
  {
    title: '运行状态',
    dataIndex: 'status',
    key: 'status',
    scopedSlots: { customRender: 'status' },
    align: 'left',
  },
  {
    title: '最近运行时间',
    dataIndex: 'lastTime',
    key: 'lastTime',
    scopedSlots: { customRender: 'lastTime' },
    align: 'left',
  },

  {
    title: '最近修改人',
    dataIndex: 'LastEditors',
    key: 'LastEditors',
    scopedSlots: { customRender: 'LastEditors' },
    align: 'left',
  },
  {
    title: '最近修改时间',
    dataIndex: 'updateTime',
    key: 'updateTime',
    scopedSlots: { customRender: 'updateTime' },
    align: 'left',
  },

  {
    title: '操作',
    dataIndex: 'operation',
    key: 'operation',
    scopedSlots: { customRender: 'operation' },
    align: 'left',
    width: '150px',
    fixed: 'right',
  },
]

export default {
  name: 'DataProcessing',
  data() {
    return {
      columns,
      statusLists: [
        { label: '未运行', value: '0' },
        { label: '运行正常', value: '1' },
        { label: '运行错误', value: '2' },
      ],
      pagination: {
        current: 1, // 默认当前页数
        pageSize: 10, // 默认当前页显示数据的大小
        total: 20,
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: (total) => `共 ${total} 条`, // 显示总数
        pageSizeOptions: ['10', '15', '20'],
      },
      processingList: [],
      searchValue: '',
    }
  },
  created() {
    this.init()
  },
  computed: {
    statusText() {
      return function (value) {
        const target = this.statusLists.find((item) => item.value === value)
        return target ? target.label : '-'
      }
    },
  },
  methods: {
    init() {
      this.getProcessingList()
    },
    /* 获取数据处理列表 */
    async getProcessingList(params = { page: 1, limit: 10 }) {
      try {
        if (this.searchValue) {
          params = { ...params, key: this.searchValue }
        }
        let res = await this.$api.getDataProcessingList(params)
        if (res.code === 0) {
          this.processingList = res.data.records
          this.pagination.total = Number(res.data.total)
          this.pagination.current = res.data.page
          this.pagination.pageSize = res.data.limit
        }
      } catch (err) {
        console.log('[ err ] >', err)
      }
    },
    /* 表格变化（分页） */
    tableChange(pagination) {
      let { current, pageSize } = pagination

      let params = {
        limit: pageSize,
        page: current,
      }
      this.getProcessingList(params)
    },
    /* 搜索 */
    onSearch(value) {
      this.getProcessingList()
    },
    /* 日志 */
    log(record) {
      // console.log('[ record ] >', record)
      this.$router.push({
        name: 'ProcessingLog',
        query: {
          id: record.id,
        },
      })
    },
  },
}
</script>

<style lang="less" scoped>
.dataProcessing {
  background-color: #fff;
  padding: 15px;
}
.card_title {
  font-family: 'PingFangSC Medium';
  // font-weight: 500;
  font-size: 15px;
  text-align: left;
  color: #292929;
  font-weight: bold;
  margin-bottom: 10px;
}

/deep/ .ant-table-align-left {
  height: 44px !important;
  padding: 0;
  padding-left: 16px;
  font-family: 'PingFang SC Bold';
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #333;
}

/deep/ .ant-table-tbody > tr > td {
  padding: 10px 16px 10px 16px;
}

.status-1 {
  color: #52c41a;
}
.status-2 {
  color: #f5222d;
}
</style>
